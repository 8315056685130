import React, { useEffect, useState } from "react";
import { Column, Columns, Container } from "bloomer";
import {
  ContEmail,
  ContFormRegistration,
  ContImgCover,
  ContPhrase,
  ContPhraseMessage,
  ContRegistration,
  Email,
  PhraseColor1,
  PhraseColor2,
  RegistrationMessage,
  StylesRegistration,
} from "./styles";
import { GlobalStyle } from "../../styles/GlobalStyles";
import CoverRegistration from "../../images/cover-resgistration.png";
import imgEmail from "../../images/icon-email.svg";
import { TransitionState } from "gatsby-plugin-transition-link";

export const Thanks = () => {
 // console.log(window.history.state)
  const [personalData, setPersonalData] = useState({
    email: "",
  });
  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      const arData = {
        email: window?.history?.state?.email
      };

      // console.log('arData =>', arData);
      setPersonalData(arData);
    }
  }, []);
  const [stateAux, setStateAux] = useState();
  return (
    <ContRegistration>
      <GlobalStyle />
      <StylesRegistration />
      <TransitionState>
        {(state) => {
          console.log('TransitionState -> state ->', state);
          if (state.current.state) {
            setStateAux(state.current.state);
          }
        }}
      </TransitionState>
      <Container>
        <Columns className={"column-side-right"}>
          <Column
            className={"form-registration"}
            isSize={{ mobile: 12, tablet: 12, desktop: 6, widescreen: 6 }}
          >
            <ContFormRegistration>
              <ContPhraseMessage>
                <PhraseColor1>
                  ¡Gracias por{" "}
                  <PhraseColor2>registrarte!</PhraseColor2>
                </PhraseColor1>
              </ContPhraseMessage>
            </ContFormRegistration>
          </Column>
        </Columns>

        <Columns className={"column-container-image z-index-low"}>
          <Column
            className={"center-column z-index-low"}
            isSize={{ mobile: 12, tablet: 12, desktop: 6, widescreen: 6 }}
          >
            <ContImgCover>
              <img src={CoverRegistration} alt={"Callmatik"} />
            </ContImgCover>
          </Column>
        </Columns>

        <Columns className={"column-side-right z-index-high"}>
          <Column
            className={"form-registration z-index-high"}
            isSize={{ mobile: 12, tablet: 12, desktop: 6, widescreen: 6 }}
          >
            <ContFormRegistration>
              <div>
                <ContEmail>
                  <div>
                    <img src={imgEmail} alt={""} />
                  </div>
                  {personalData != null && personalData != undefined && personalData?.email !== "" ? (
                    <Email>
                    { 
                      personalData.email
                    }
                  </Email>
                  ) : (
                  <Email>
                    Sin correo por favor regrese al registro
                  </Email>
                  )
                }
                </ContEmail>
                <RegistrationMessage>
                  Te hemos enviado un correo para validar la existencia de tu
                  dirección, por favor ingresa a tu cuenta de correo y sigue las
                  instrucciones
                </RegistrationMessage>
              </div>
            </ContFormRegistration>
          </Column>
        </Columns>
      </Container>
    </ContRegistration>
  );
};
