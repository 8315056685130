import styled, {createGlobalStyle, css} from 'styled-components';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesRegistration = createGlobalStyle`
  .form-registration{
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .column-side-right{
    display: flex;
    justify-content: right;
  }
  .column-container-image{
    
    @media(min-width: 300px) and (max-width: 767px){
      top: 0px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 0px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      position: absolute;
      top: 50px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      position: absolute;
      top: 50px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      position: absolute;
      top: 60px;
    }
    @media(min-width: 1920px){
      position: absolute;
      top: 70px;
    }
  }
`
export const ContRegistration = styled.div`
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: ${GlobalBackgroundColors.bgPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    min-height: 700px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    min-height: 700px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    min-height: 750px;
  }
  @media(min-width: 1920px){
    min-height: 750px;
  }
`
export const ContImgCover = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContFormRegistration = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 90%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 80%;
  }
  @media(min-width: 1920px){
    width: 80%;
  }
`
export const ContPhrase = styled.div`
  height: 25px;
  margin-bottom: 30px;
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 100px;
    text-align: center;
  }
`
export const ContPhraseMessage = styled.div`
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 0px;
    margin-bottom: 30px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 0px;
    margin-bottom: 30px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: 50px;
    margin-bottom: 70px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-top: 80px;
    margin-bottom: 100px;
  }
  @media(min-width: 1920px){
    margin-top: 100px;
    margin-bottom: 120px;
  }
`
export const StylesPhrase = css`
  font: 42px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 46px;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 28px;
    line-height: 36px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 32px;
    line-height: 36px;
  }
`
export const PhraseColor1 = styled.h1`
  color: ${GlobalColors.colorPrimary};
  ${StylesPhrase}
`
export const PhraseColor2 = styled.span`
  color: ${GlobalColors.colorSecondary};
  ${StylesPhrase}
`
export const ContForm = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 90%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 70%;
  }
  @media(min-width: 1920px){
    width: 60%;
  }
`
export const TextOptional = styled.div`
  margin-right: 10px;
  margin-bottom: 50px;
  font: 12px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
  color: ${GlobalColors.colorPrimary};
`
export const ContEmail = styled.div`
  display: flex;
  align-items: center;
  //justify-content: center;
  justify-content: flex-start;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 20px;
    justify-content: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 20px;
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-bottom: 30px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-bottom: 50px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-bottom: 70px;
  }
  @media(min-width: 1920px){
    margin-bottom: 70px;
  }
`
export const Email = styled.div`
  width: 70%;
  //width: auto;
  margin-left: 15px;
  //padding-left: 5px;
  padding: 0 15px;
  border-bottom: 1px solid ${GlobalColors.colorPrimary} !important;
  font: 18px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${GlobalColors.colorPrimary};
  text-align: center;

  @media(min-width: 768px) and (max-width: 1023px){
    width: auto;
  }
`
export const RegistrationMessage = styled.div`
  width: 100%;
  margin-top: 25px;
  font: 18px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${GlobalColors.colorText};
  
  @media(min-width: 300px) and (max-width: 1023px){
    text-align: center;
  }
`
export const ErrorMessage = styled.h1`
  margin: 1.5rem;
  font-size: 1.5rem;
  color: ${GlobalColors.colorWarning};
`

export const MessageLabel = styled.label`
  color: ${GlobalColors.colorPrimary};
  font: 13px ${GlobalFonts.fontBold};
`